<!--@Time : 2021/12/13 10:37-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <div>
    <v-row class="mt-9">
      <v-col cols="12">
        <div id="container" style="position: relative">
          <Loading v-if="!data.length" :loading="loading"></Loading>
        </div>
        <div v-if="chart === null" style="height: 600px"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import {Chart} from "@antv/g2";
import DataSet from "@antv/data-set";
import {getParticipate} from "../../api/solar/country";

export default {
  name: "chart",
  components: {Loading},
  data() {
    return {
      // 可视化用户数据
      chart: null,
      data: [],
      loading: 'loading...',
    }
  },
  created() {
  },

  mounted() {
    this.setChart()
  },

  methods:{
    setChart(){
      fetch('https://gw.alipayobjects.com/os/antvdemo/assets/data/world.geo.json')
          .then(res => res.json())
          .then(mapData => {
            this.chart = new Chart({
              container: 'container',
              autoFit: true,
              height: 600,
              padding: [0, 0]
            });
            this.chart.tooltip({
              showTitle: false,
              showMarkers: false,
            });
            // 同步度量
            this.chart.scale({
              longitude: {
                sync: true,
              },
              latitude: {
                sync: true,
              },
            });
            this.chart.axis(false);
            this.chart.legend(false);

            // 绘制世界地图背景
            const ds = new DataSet();
            const worldMap = ds.createView('back')
                .source(mapData, {
                  type: 'GeoJSON'
                });
            const worldMapView = this.chart.createView();
            worldMapView.data(worldMap.rows);
            worldMapView.tooltip(false);
            worldMapView.polygon().position('longitude*latitude').style({
              fill: '#CED4D9',
              stroke: '#F2F4F5',
              lineWidth: 0.5
            }).label('name', {
              layout: {
                type: "fixed-overlap",
              },
              offset: 0,
              style: {
                fill: "black",
                stroke: "#fff",
                lineWidth: 2
              }
            });

            getParticipate().then( (res) => {
              this.data = res

              const userDv = ds.createView()
                  .source(this.data)
                  .transform({
                    geoDataView: worldMap,
                    field: 'Country',
                    type: 'geo.region',
                    as: ['longitude', 'latitude']

                  });
              const userView = this.chart.createView();
              userView.data(userDv.rows);
              userView.scale({
                trend: {
                  alias: 'Country'
                }
              });
              userView.polygon()
                  .position('longitude*latitude')
                  .color('trend', '#6395F9')
                  .tooltip('Country*Schools')
                  .style({
                    fillOpacity: 0.85
                  })
                  .animate({
                    leave: {
                      animation: 'fade-out'
                    }
                  });
              userView.interaction('element-active');

              this.chart.render();
            })
          });
    },

  }
}
</script>

<style scoped>

</style>